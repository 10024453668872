<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">시스템메시지관리</h1>
    <div class="box-wrap  d-flex">

      <div class="box-ct">
        <div class="form-inp sm">

          <v-select
              label="회사구분"
              :items="this.$store.getters['userStore/GE_USER_ROLE'].company"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              placeholder="선택"
              v-model="aspNewcustKey"
          >
            <template v-slot:label>
              회사구분
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
          </v-select>

        </div>

        <div class="form-inp sm ml-2">
          <v-select
              :items="mixin_common_code_get(this.common_code, 'TALK017','선택')"
              outlined
              hide-details
              label="메시지분류"
              v-model="sysmsgType"
          ></v-select>
        </div>
        <div class="form-inp sm ml-2">
          <v-select
              :items="mixin_common_code_get(this.common_code, 'TWB001','선택')"
              outlined
              hide-details
              label="사용여부"
              placeholder="선택"
              v-model="useYn"
          ></v-select>
        </div>
      </div>
      <div class="ml-auto align-self-center">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2"  v-on:click="getListOrNot" id ="btnSearch">조회</v-btn>
      </div>
    </div>
    <!-- 상겸결과 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">시스템메시지현황
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" outlined class="btn-default ml-2" v-on:click="registNew" id="btnNew">신규</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" v-on:click="submitDeleteConfirm" id="btnDelete">삭제</v-btn>
        </div>
        <v-dialog v-if="dialogM810610P01" max-width="655" persistent v-model="dialogM810610P01" content-class="square-modal min-auto">
          <dialog-M810610P01
              :param="M810610P01Param"

              @hide="hideDialog('M810610P01')"
              @hideAndGetList="hideAndGetList('M810610P01')"
          >
          </dialog-M810610P01>
        </v-dialog>
        <v-dialog v-if="dialogM810610P02" max-width="655" persistent v-model="dialogM810610P02"  content-class="square-modal min-auto">
          <dialog-M810610P02
              :param="M810610P02Param"

              @hide="hideDialog('M810610P02')"
              @hideAndGetList="hideAndGetDetailList('M810610P02')"
          >
          </dialog-M810610P02>
        </v-dialog>
      </h2>
      <div class="box-ct">
        <v-data-table
            dense
            height="513px"
            :headers="divGrid01"
            :items="divGrid01DataText"
            :items-per-page="30"
            item-key="id"
            :page.sync="divGrid01Page"
            hide-default-footer
            class="grid-default"
            show-select
            fixed-header
            @page-count="divGrid01PageCount = $event"
            no-data-text="검색된 결과가 없습니다."
            v-model="selectedDivGrid01List"
            v-on:dblclick:row="getGroupCdView"
            v-on:click:row="getListSel"
            :item-class="isActiveRow"
        >
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
              v-model="divGrid01Page"
              :length="divGrid01PageCount"
              :total-visible="divGrid01TotalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev">
          </v-pagination>
        </div>
        <p class="caption text-right">
          총<strong class="clr-error">{{ divGrid01DataText.length }}</strong
        >건
        </p>
      </div>

    </div>
    <!--// 상담결과 -->
  </div>
</template>
<script>
import DialogM810610P02 from "./M810610P02.vue";
import DialogM810610P01 from "./M810610P01.vue";
import api from '@/store/apiUtil.js';
import {mixin} from "@/mixin/mixin.js";
export default {
  name: "MENU_M810610", //name은 'MENU_' + 파일명 조합
  mixins:[mixin], // mixin 등록
  components: {
    DialogM810610P01,
    DialogM810610P02,
  },
  data() {
    return {
      common_code: [],
      HEADERResult:'',
      DATAResult:'',
      HEADER_SERVICE:'',
      HEADER_GRID_ID:'',
      headers:{},
      sendData: {},
      URLData:'',
      aspNewcustKey:'',
      useYn:'',
      sysmsgType:'',
      msgClName:'',
      msgTypeName:'',
      msgDesc:'',
      msgTime:'',
      msgType:'',
      msgContent:'',
      msgCl:'',
      regId:'',
      regDttm:'',
      regDeptCd:'',
      updDeptCd:'',
      updDttm:'',
      updId:'',
      itProcessing:'',
      linksType: '',
      systemMsgId: '',
      sndrcvCd: '',
      procId: '',
      rowNumber:'',
      twbPagingTotCount:'',
      divgrid01Rownum:'',
      dialogM810610P01: false,
      dialogM810610P02: false,
      M810610P01Param:{},
      M810610P02Param:{},
      menu1: false,
      menu2: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dates: [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],
      dropItems: [],
      dropItems2 : [],
      dropItems3: [],
      divGrid01: [
        { text: "No", value: "index", align: "center", sortable: true },
        {
          text: "메시지분류",
          value: "MSG_CL_NAME",
          align: "center",
          sortable: true,
        },
        {
          text: "메시지타입",
          value: "MSG_TYPE_NAME",
          align: "center",
          sortable: true,
        },
        {
          text: "메시지제목",
          value: "MSG_DESC",
          align: "left",
          sortable: true,
        },
        {
          text: "사용여부",
          value: "USE_YN",
          align: "center",
          sortable: true,
        },
      ],
      selectedDivGrid01List:[],
      selMsgClName:{},
      divGrid01DataText: [],
      divGrid01Page: 1,
      divGrid01PageCount: 0,
      divGrid01ItemsPerPage: 20,
      divGrid01TotalVisible: 50,
      detailData: {},

    }
  },
  methods: {
    async init(){
      let codeName = ["TALK017","TWB001"];
      this.common_code= await this.mixin_common_code_get_all(codeName);

      },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    getListOrNot(){
      if(this.aspNewcustKey == '' || this.aspNewcustKey == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
      }else{
        this.getList();
      }
    },
    getList(){
      this.headers = this.initHeaders;
      this.URLData = "/api/chat/setting/system-mssage-manage/list";
      this.headers["SERVICE"] ="chat.setting.system-mssage-manage";
      this.headers["METHOD"] = "list";
      this.headers["ASYNC"] = false;
      this.headers["ROW_CNT"] = "50";
      this.headers["PAGES_CNT"] = "1";
      this.headers["GRID_ID"] = "divGrid01";

      this.sendData= {};
      this.sendData["SYSMSG_TYPE"] = this.sysmsgType;
      this.sendData["USE_YN"] = this.useYn;
      this.sendData["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
      this.selMsgClName = '';
      this.result('S');
    },

    getDivGrid01Callback(response){
      let header = response.HEADER;
      let data = response.DATA;
      this.divGrid01DataText = data;
      this.selectedDivGrid01List = [];
      let idx = 1;
      let dataText = this.divGrid01DataText;
      for(let i in dataText){
        let id = dataText[i].ASP_CUST_KEY + "_" + dataText[i].SYSTEM_MSG_ID;
        this.divGrid01DataText[i]["id"]= id;
        this.divGrid01DataText[i]["index"]= idx++;
      }
    },
    getListSel(item){
      // 선택한 아이템 저장
      this.selMsgClName = item;
      this.detailData = JSON.parse(JSON.stringify(item));

    },
    isActiveRow(item){
      return item.id== this.detailData.id? 'active':'';
    },
    submitDeleteConfirm(){
      if(this.selectedDivGrid01List.length === 0){
        this.common_alert('체크된 항목이 존재하지 않습니다.', 'error')
        return;
      }
      this.common_confirm('삭제하시겠습니까?', this.submitDeleteGrid01, '', this.closeAlert, '', 'error')
    },

    submitDeleteGrid01(){
      this.headers = this.initHeaders;
      this.URLData = "/api/chat/setting/system-mssage-manage/delete";
      this.headers["SERVICE"] = "chat.setting.system-mssage-manage";
      this.headers["METHOD"] = "delete";
      this.headers["ASYNC"] = false;
      let deleteList = [];
      for(let i in this.selectedDivGrid01List){
        let obj = {
          divGrid01_CHECKBOX:'1',
          MSG_CL_NAME: this.selectedDivGrid01List[i].MSG_CL_NAME,
          divGrid01_ROWNUM: this.selectedDivGrid01List[i].index,
          MSG_TYPE_NAME: this.selectedDivGrid01List[i].MSG_TYPE_NAME,
          MSG_DESC: this.selectedDivGrid01List[i].MSG_DESC,
          USE_YN: this.selectedDivGrid01List[i].USE_YN,
          SYSTEM_MSG_ID: this.selectedDivGrid01List[i].SYSTEM_MSG_ID,
          MSG_CL: this.selectedDivGrid01List[i].MSG_CL,
          MSG_TIME: this.selectedDivGrid01List[i].MSG_TIME,
          MSG_TYPE: this.selectedDivGrid01List[i].MSG_TYPE,
          LINKS_TYPE: this.selectedDivGrid01List[i].LINKS_TYPE,
          MSG_CONTENT: this.selectedDivGrid01List[i].MSG_CONTENT,
          SNDRCV_CD: this.selectedDivGrid01List[i].SNDRCV_CD,
          ASP_NEWCUST_KEY: this.aspNewcustKey,
          REG_DTTM: this.selectedDivGrid01List[i].REG_DTTM,
          IT_PROCESSING: this.selectedDivGrid01List[i].IT_PROCESSING,
          REGR_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          REG_DEPT_CD:"X",
          DATA_FLAG:"N",
        };
        deleteList.push(obj);
      }
      this.sendData  = {
        list:deleteList
      }
      this.result();
    },
    submitDeleteGrid01CallBack(response){//
      this.common_alert('정상처리 되었습니다.', 'done')
      this.$emit("submitDelete", null);
      this.getList();

     // this.result()

    },
    closeAlert(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    hideAndGetDetailList(type){
      this.hideDialog(type);
      this.selectedDivGrid01List = [];
      this.getList(this.selMsgClName);
    },
    registNew(){//신규
      if(this.aspNewcustKey == '' || this.aspNewcustKey == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
      }else{
        this.M810610P02Param = {
          headerTitle: "시스템메시지 신규등록",
          data: this.selMsgClName,
          type: "C",
          ASP_NEWCUST_KEY:this.aspNewcustKey,
        };
        this.showDialog('M810610P02');
      }

    },
    getGroupCdView(){//시스템메시지 상세 팝업 (수정)
      // 상세
      this.M810610P01Param = {
        headerTitle: "시스템메시지 상세",
        data: this.selMsgClName,
        type: "R",
        ASP_NEWCUST_KEY:this.aspNewcustKey,
      };
      this.showDialog('M810610P01');
    },
    hideAndGetList(type){
      this.selectedDivGrid01List = [];
      this.hideDialog(type);
      this.getList(this.selMsgClName);
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    async result(requestData) {
      let head = '';
      let dataa = '';
      //결과값을 보여주는 부분 -> 실제 사용 시 dataa의 value를 사용
      //api 호출 부분
      await api.post(this.URLData,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          this.sendData
          , //api에서 사용될 data 입력
          {
            head: this.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            if(requestData === 'S'){
            } else {
              if(!response.data.HEADER.ERROR_FLAG){
              } else {
                this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
                return
              }
            }
            head = JSON.stringify(response.data.HEADER);
            dataa = JSON.stringify(response.data.DATA);
            if (typeof (response.data) === "string") {
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg: responseData.HEADER.ERROR_MSG,
                iconClass: "svg-error-lg",
                type: "default",
              });
              return;
            }
            if (response.data.HEADER.METHOD === "list" ) {
              this.getDivGrid01Callback(response.data);
            }
            else if (response.data.HEADER.METHOD === "delete" || response.data.HEADER.METHOD === undefined) {
              this.submitDeleteGrid01CallBack(response.data)
            }
          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }) //api호출 에러 작업 수행
          this.HEADERResult = head;
          this.DATAResult = dataa;
    },
  },
  mounted(){
    this.init();
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + ' ~ ' + newEndDate;
    },
    initHeaders() {
      return this.headers = {
        SERVICE: 'chat.setting.system-mssage-manage',
        METHOD: "",
        TYPE: 'BIZ_SERVICE',
      };
    },
  },
};
</script>

<style >

</style>